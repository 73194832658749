import headerImage from './assets/header.png';

function Home() {
  return (
    <div className="App">
          <header class="header">
        <div class="header-left">
            <img src={headerImage} alt="Logo" class="header-logo" />
        </div>
        <div class="header-right">
            <h1 class="header-text">Weather into <br /><b>Revenue</b></h1>
            <h2 class="header-subtext">Harness real-time weather data to craft irresistible promotions</h2>
               <a href="https://apps.shopify.com/weatherupsell" target="_blank" class="header-button-link" rel="noreferrer">
            <button class="header-button">Get Started</button>
            </a>
        </div>
    </header>
     <footer class="footer">
        <div class="footer-content">
            <span>&copy; 2024 Weather Upsell by Architect Spot LLC</span>
            <a href="./privacy-policy" target="_blank" rel="noreferrer" class="footer-link">Privacy Policy</a>
        </div>
    </footer>
    </div>
  );
}

export default Home;
