import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import './App.css';


const App = () => {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    </Router>
  );
};

export default App;
